<template>
  <div class="makelaar">
    <v-divider>  </v-divider>
    <v-container>
      
      <div class="space">
        <div class="main-section-content">
          <v-row>
            <v-col cols="7" data-aos="fade-right">
              <h2>CRM software voor makelaar</h2>

              <p>
                CRM software is speciaal gebouwd voor makelaars van elke
                grootte. CRM software voor makelaar biedt geavanceerd
                verkoopprocesbeheer, relatiebeheer, vastgoedbeheer en
                automatisering voor makelaars en vastgoedontwikkelaars. Breng
                meer deals binnen met het alles-in-een CRM platform voor
                onroerend goed dat speciaal voor jouw bedrijf gemaakt is. CRM
                software voor versnelde verkoop in onroerend goed Een alles in 1
                CRM software pakket voor het uitvoeren van je dagelijkse werk.
                Opdrachten, relaties, e-mail, taken, agenda, facturen,
                documenten, meldingen en opdrachten op 1 plek in een CRM
                applicatie. Vraag een gratis demo aan!
              </p>

              <v-btn
                color="red"
                class="ma-2 white--text"
                @click="goToContact()"
              >
                Neem contact op
              </v-btn>
            </v-col>
            <v-col>
              <v-card class="mx-auto" max-width="400" elevation="9" shaped>
                <v-img
                  class="white--text align-end"
                  height="350px"
                  src="@/assets/about.png"
                >
                </v-img>
              </v-card>
            </v-col>
          </v-row>
        </div>
        <div class="main-section-content">
          <v-row style="margin-top:40px">
            <v-col>
              <v-card class="mx-auto" max-width="400" elevation="9" shaped>
                <v-img
                  class="white--text align-end"
                  height="350px"
                  src="@/assets/flexoffice.jpg"
                >
                </v-img>
              </v-card>
            </v-col>
            <v-col cols="7" data-aos="fade-left">
              <h3><b>Wat is CRM software makelaardij?</b></h3>

              <p>
                CRM software makelaardij is een systeem dat de relaties tussen
                koper, verkoper, makelaar, projectontwikkelaar en financiële
                instellingen helpt beheren en tegemoet komt aan de veelvoudige
                eisen van makelaars en vastgoedbedrijven. Van het beheren van
                inkomende aanvragen tot het opstellen van prijsopgaven. En van
                het verkopen van commercieel onroerend goed tot het beheren van
                eigendommen. Het CRM systeem voor onroerend goed verzorgt voor
                makelaars het procesbeheer van begin tot eind.
              </p>

              <h3><b>Hoe is CRM belangrijk voor makelaars? </b></h3>
              <p>
                In de makelaardij draait alles om het opbouwen van vertrouwen.
                Het is daarom geen verrassing dat een groot aandeel van de
                verkoop van nieuw onroerend goed doorverwijzingen zijn van ofwel
                bestaande contacten, eerdere klanten, familie, vrienden, of
                verwanten. Bovendien is, als het om makelaardij gaat, verkopen
                of kopen een langdurig proces, met veel follow-ups en
                heronderhandelingen. In zulke scenario's biedt het implementeren
                van een CRM software voor onroerend goed makelaars een
                efficiënte manier om contacten te beheren, taken in te plannen,
                de voortgang te bewaken en georganiseerd te blijven om
                succesvolle relaties op te bouwen en verkopen te sluiten.
              </p>
              <v-btn
                color="red"
                class="ma-2 white--text"
                @click="goToContact()"
              >
                Neem contact op
              </v-btn>
            </v-col>
          </v-row>
        </div>

        <div class="main-section-content">
          <v-row style="margin-top:40px">
            <v-col cols="7" data-aos="fade-right">
              <h3><b>Voordelen van CRM software</b></h3>

              <p>
                o Verbetering in lead conversie<br />
                o Inkomstenstijging per makelaar<br />
                o Verbetering van klantbehoud<br />
                o Verhoging van klanttevredenheid<br />
              </p>

              <p>
                Wil je weten hoe het CRM software pakket voor onroerend goed
                binnen jouw vastgoedbedrijf functioneert? We laten je graag een
                demo zien. We staan klaar om je te helpen met alle informatie
                die je nodig hebt, al je vragen te beantwoorden, en je door onze
                aangepaste demo voor je zakelijke behoeften te leiden. Neem
                gewoon even contact met ons op voor meer informatie over onze
                CRM applicatie.
              </p>
              <v-btn
                color="red"
                class="ma-2 white--text"
                @click="goToContact()"
              >
                Ik wil meer informatie
              </v-btn>
            </v-col>
            <v-col>
              <v-card class="mx-auto" max-width="400" elevation="9" shaped>
                <v-img
                  class="white--text align-end"
                  height="350px"
                  src="@/assets/crm.jpg"
                >
                </v-img>
              </v-card>
            </v-col>
          </v-row>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
export default {
  data: () => ({}),

  methods: {
    goToContact() {
      this.$router.push("/Contact");
    },
    goToAccount() {
      this.$router.push("/Inloggen");
    },
  },
};
</script>

<style scoped>
.space {
  margin-top: 15px;
  margin-bottom: 8px;
  border-style: non
}
.makelaar h2{
    color:red
}
.makelaar h3{
    color:red
}
</style>
