<template>
  <HelpInfo />
</template>

<script>
import HelpInfo from "@/components/HelpInfo";

export default {
  data() {
    return {
      data: [],
    };
  },
  components: {
    HelpInfo,
  },
  metaInfo: {
    title: "Ascosoftware | Handige tips en trucs ",
    htmlAttrs: {
      lang: "EN",
      amp: true,
    },
     meta: [
      { charset: "utf-8" },
      {
        name: "description",
        content:
          "Ascosoftware.",
      },
      { name: "viewport", content: "width=device-width, initial-scale=1" },
    ],
  },
};
</script>