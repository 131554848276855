<template>
<div class="kassa">
    <v-divider>  </v-divider>
  <v-container>
<v-row>
    <v-col data-aos="fade-up">
    <h2>Diensten Asco kassa software</h2>
    <p>
    De diensten van Asco kassa software zijn beschikbaar in Nederland en België.
    Onze diensten zijn breed toepasbaar. Wij leveren dan ook kassasoftware aan
    de detailhandel, horeca, kapsalons en andere retail bedrijven. Onze Asco
    kassasoftware werkt in een Cloud omgeving, wat betekent dat alle gegevens
    real-time en altijd beschikbaar zijn. Ook kan je kiezen voor een extra
    module waarbij de kassa koppelen aan je WordPress of Magenta website.<br>

   <span style="color:green"> &#10003;</span> Ons kassasysteem kan gekoppeld worden met WordPress of Magenta website.<br>
   <span style="color:green"> &#10003;</span> Saas oplossing <br>
      <span style="color:green"> &#10003;</span> Onze webmodule
              biedt de klant veel voordelen. Zoals actuele voorraad- en
              artikelinformatie, gemak een eenvoud door geïntegreerde koppeling
              met de Asco software en meer klanten.<br>
         <span style="color:green"> &#10003;</span>Goed beveiligd netwerk.
    </p>
    
         
  </v-col>

  <v-col>

      <v-card class="mx-auto" max-width="400" elevation="9" shaped>
                <v-img
                  class="white--text align-end"
                  height="350px"
                  src="@/assets/kassa.png"
                >
                </v-img>
              </v-card>
   

  </v-col>
</v-row>

<v-row>

    <v-col>
 <v-card class="mx-auto" max-width="500" elevation="9" shaped>
                <v-img
                  class="white--text align-end"
                  height="350px"
                  src="@/assets/kassaimage.png"
                >
                </v-img>
              </v-card>
   
  </v-col>

    <v-col data-aos="fade-up">
    <h2>Kassa software retail</h2>

    Met onze kassa software retail heb je op elk gewenst moment een helder
    overzicht van al je verkoopgegevens. De software legt elke transactie met de
    bijbehorende gegevens vast. In overzichtelijke rapportages krijg je inzicht
    in marge en omzet. Tevens zie je welke producten best sellers zijn en welke
    artikelen minder goed verkopen, zodat je tijdig je inkoopbeleid of
    verkoopstrategie erop kan aanpassen. Onze kassa software retail is erg
    gebruiksvriendelijk en draait op ieder apparaat met een browser. In de
    meeste gevallen kan bestaande hardware gebruikt worden en is er geen extra
    investering nodig.
      </v-col>
</v-row>

<v-row>
     <v-col data-aos="fade-up">
    <h2>Kassa software kapsalon</h2>

    Asco Software is een Nederlands bedrijf met unieke software voor Nederlandse
    en Belgische kapsalons. Onze kassa software kapsalon bespaar je tijd. De
    kassa is direct te koppelen met onder andere klantbeheer, webshop, agenda en
    unieke mailacties. Snel, eenvoudig in beheer en uitgebreid met
    gebruiksvriendelijke software. De kassa software kapsalon is beschikbaar op
    mobiele apparaten, tablet en computer, altijd en overal. Uiteraard bieden
    wij ook software ondersteuning aan, zodat je meer uit de kassa systemen te
    halen is. Heb je een kapsalon en wil je overstappen op slimme software van
    Asco? Maak een afspraak en wij beantwoorden al jouw vragen.
      </v-col>

        <v-col>
 <v-card class="mx-auto" max-width="500" elevation="9" shaped>
                <v-img
                  class="white--text align-end"
                  height="350px"
                  src="@/assets/hairsalon.png"
                >
                </v-img>
              </v-card>
   
          </v-col>
</v-row>
<v-row>

    <v-col>
 <v-card class="mx-auto" max-width="500" elevation="9" shaped>
                <v-img
                  class="white--text align-end"
                  height="350px"
                  src="@/assets/horeca.png"
                >
                </v-img>
              </v-card>
      </v-col>

        <v-col data-aos="fade-up">
    <h2>Kassa software horeca</h2>

    Met de kassa software horeca van Asco haal je het optimale uit je kassa. Wil
    jij meer inzicht halen uit verkochte producten? Of wil je dat het personeel
    efficiënter te werk gaat? Ons horeca kassa systeem is speciaal ingericht
    voor restaurants. Door gebruik te maken van onze kassa software horeca zorg
    jij ervoor dat gasten een ervaring krijgen om niet te vergeten.
    Kassasoftware horeca biedt ten opzichte van een vaste kassa diverse functies
    waardoor je niet alleen overzichtelijker, maar ook efficiënter werkt. De
    interface is naar eigen wens in te stellen, de voorraad wordt automatisch
    bijgewerkt en integratie met bedrijfssoftware is ook mogelijk. Meer weten?
    Neem contact op met Asco software. Kassa software, hardware en kassa
    systemen voor het MKB. Breed aanbod voor horeca, kapsalons en retail.
      </v-col>
    </v-row>
  </v-container>
</div>
</template>

<script>
export default {};
</script>

<style>

.kassa h2{
  color:red;
}
</style>
