<template>
  <Wordpress />
</template>

<script>
import Wordpress from "@/components/Wordpress";
export default {
  data() {
    return {
      data: [],
    };
  },
  components: {
    Wordpress,
  },
  metaInfo: {
    title: "Welkom bij Ascosoftware | Wordpress plug-ins Nederland ",
    htmlAttrs: {
      lang: "EN",
      amp: true,
    },
     meta: [
      { charset: "utf-8" },
      {
        name: "description",
        content:
          "Wordpress applicatie",
      },
      { name: "viewport", content: "width=device-width, initial-scale=1" },
    ],
  },
};
</script>