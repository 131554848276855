<template>
  <MakelaarsCRM />
</template>

<script>
import MakelaarsCRM from "@/components/MakelaarsCRM";
export default {
  data() {
    return {
      data: [],
    };
  },
  components: {
    MakelaarsCRM,
  },
  metaInfo: {
    title: "CRM software makelaardij | CRM applicatie | Ascosoftware ",
    htmlAttrs: {
      lang: "EN",
      amp: true,
    },
    meta: [
      { charset: "utf-8" },
         {
        name: "keywords",
        content:
          "crm makelaars,crm software voor makelaar,software makelaardij,software crm,crm software real estate,real estate crm software,crm applicatie"
      },
      {
        name: "description",
        content:
          "Alles in 1 CRM software makelaardij zorgt voor meer verkopen. Relatiebeheer, vastgoedbeheer, automatisering. CRM software voor makelaar en real estate agency.",
      },
      { name: "viewport", content: "width=device-width, initial-scale=1" },
    ],
  },
};
</script>