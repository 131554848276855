<template>
<div>
  <v-divider>  </v-divider>
  <v-container>


<v-row>
  <v-col>
        <h1  style="color:red" class="text-center">Wordpress</h1>
   <p> WooCommerce is de beste oplossing om een ​​dropshipping-bedrijf te starten,
    vooral omdat het gratis is. U kunt verschillende WooCommerce-extensies
    installeren voor extra functies om SEO voor WooCommerce te verbeteren en uw
    online winkel naar een hoger niveau te tillen! Daarnaast kun je de Zalando
    WooCommerce-plug-in gebruiken om je winkel te integreren met Zalando of een
    van onze andere WooCommerce-plug-ins voor marktplaatsen kiezen. Zorg er
    eindelijk voor dat uw winkel voldoet aan de wet voor cookies met de GDPR
    Cookie Consent-plug-in.</p>
 <v-btn
                color="red"
                class="ma-2 white--text"
                @click="goToContact()"
              >
                Ik wil meer informatie
              </v-btn>
  </v-col>

  <v-col>
 <v-card class="mx-auto" max-width="500" elevation="9" shaped>
              <v-img
                class="white--text align-end"
                height="400px"
                src="@/assets/modules/wordpess.jpg"
              >
              </v-img>


 </v-card>
  </v-col>
</v-row>
  <section class="wordpress">
      <h2 class="text-center" style="color:red">Onze Koppelingen</h2>
      <v-container>
        <v-row>
          <v-col cols="12" sm="6" md="3" data-aos="flip-right">
            <v-card>
              <v-img
                src="@/assets/modules/wordpess.jpg"
                height="250px"
                class="grey darken-4"
              ></v-img>
              <v-card-title class="text-h6">
                <v-btn icon @click="gotoMoneybird()">
                
                </v-btn>
              </v-card-title>
            </v-card>
          </v-col>

          <v-col cols="12" sm="6" md="3" data-aos="flip-right">
            <v-card>
              <v-img
            src="@/assets/modules/amazon.png"
                height="250px"
                contain
                class="grey darken-4"
              ></v-img>
              <v-card-title class="text-h6">
                <v-btn icon @click="gotoJouwmakelaar()">
                
                </v-btn>
              </v-card-title>
            </v-card>
          </v-col>

          <v-col cols="12" sm="6" md="3" data-aos="flip-left">
            <v-card>
              <v-img
                src="@/assets/modules/bolcom.png"
                height="250px"
                class="grey darken-4"
              ></v-img>
              <v-card-title class="text-h6">
                <v-btn icon @click="gotoJouwbedrijfsmakelaar()">
                
                </v-btn>
              </v-card-title>
            </v-card>
          </v-col>

          <v-col cols="12" sm="6" md="3" data-aos="flip-left">
            <v-card>
              <v-img
                 src="@/assets/modules/edc.svg"
                height="250px"
                contain
                class="grey darken-4"
              ></v-img>
              <v-card-title class="text-h6">
                <v-btn icon @click="gotoAmeliewebdesign()">
                
                </v-btn>
              </v-card-title>
            </v-card>
          </v-col>
         
        </v-row>
        <div style="margin-top:50px">
         <v-btn color="red" style="color:white" class="text-center" to="contact">Nieuwsgierig geworden? Neem contact met ons op  </v-btn>
        </div>
      </v-container>
    </section>
  </v-container>
</div>
</template>


<script>
export default {
  data: () => ({}),

  methods: {
    goToContact() {
      this.$router.push("/Contact");
    },
    goToAccount() {
      this.$router.push("/Inloggen");
    },
  },
};
</script>

<style>
.wordpress{
  margin-top:90px
}
  .wordpress {
    padding: 50px 0px;
    text-align: center;
  }
</style>
